@font-face {
  font-family: 'Roboto';
  src: url('./Roboto-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./Roboto-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./Roboto-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./Roboto-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoMono';
  src: url('./RobotoMono-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoMono';
  src: url('./RobotoMono-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoMono';
  src: url('./RobotoMono-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}
